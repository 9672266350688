.layers_widget_9-container
{
    padding: 20px;
    background-size: cover;
    border-bottom: 3px solid #013401;
}

.layers_widget_9-container-header
{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.layers_widget_9-container-header-decor
{
}
.layers_widget_9-container-header-title
{
    font-size: 2.2rem;
    text-align: center;
}
.layers_widget_9-container-items
{
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.layers_widget_9-container-items-el
{
    min-width: 180px;
    min-height: 300px;
    margin: 20px;
    animation: layers_widget_9-container-zoom-out 0.3s ease;
    animation-fill-mode: forwards;

}
.layers_widget_9-container-items-el:hover 
{
    animation: layers_widget_9-container-zoom-in 0.3s ease;
    animation-fill-mode: forwards;
}

@keyframes layers_widget_9-container-zoom-in {
    from{
        transform: scale(1);
    }
    to{
        transform: scale(1.1);
    }
}
@keyframes layers_widget_9-container-zoom-out {
    from{
        transform: scale(1.1);
    }
    to{
        transform: scale(1);
    }
}
.layers_widget_9-container-items-el-img
{
    display: flex;
    justify-content: center;
    align-items: center;
}
.layers_widget_9-container-items-el-img img 
{
    height: 160px;
    object-fit: contain;
}
.layers_widget_9-container-items-el-decor
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
}
.layers_widget_9-container-items-el-decor img 
{
    height: 50px;
    object-fit: contain;
}
.layers_widget_9-container-items-el-title
{
    padding: 20px;
    text-align: center;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    font-size: 0.8rem;
}
.layers_widget_9-container-items-el-title-special
{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background-color: #009900;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: bold;
}
.layers_widget_9-container-items-el-title-special:hover 
{
    color: white;
    transition: all 0.3s ease;
    cursor: pointer;
}
.layer_widgget_10_special
{
    height: 90%;
    object-fit: contain;
}
.layer_widgget_10_special:hover 
{
    transform: scale(1.1);
    transition: all 0.3s ease;
}
.layer_widgget_10_special-content
{
    height: 90%;
    padding: 20px;
    margin-top: 30px;
}
.layer_widgget_10_special-content button
{
    margin-top: 40px;
    padding: 10px 20px;
    outline: none;
    font-size: 1.2rem;
    background-color: #006600;
    color: white;
    border-radius: 10px;

}
.layer_widgget_10_special-content button:hover 
{
    background-color: #007c00;
    transform: scale(1.1);
    transition: all 0.3s ease;
    cursor: pointer;
}

/* mobile version */
@media only screen and (max-width: 600px) {
.layers_widget_9-container-items-el
{
    min-height: auto;
    margin: 5px;

}
.layers_widget_9-container-items-el-img img 
{
    height: 100px;
}
.layers_widget_9-container-items-el-decor img
{
    height: 25px;
}
.layers_widget_9-container-items-el-title
{
    padding: 10px;
}

}
@media only screen and (max-width: 600px) {
.layers_widget_9-container-items-el
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 200px; */
    min-width: 150px;
}
.layers_widget_9-container-items-el-img img 
{
    height: 60px;
}
.layers_widget_9-container-items-el-decor img
{
    height: 20px;
}
.layers_widget_9-container-items-el-title
{
    padding: 5px 10px;
    font-size: 0.9rem;
    max-width: 120px;
}
    
}