.atoms_buttons_title_1-header
{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.atoms_buttons_title_1-header img 
{
    height: 100px;
    object-fit: contain;
}
.atoms_buttons_title_1-header-decor
{
}
.atoms_buttons_title_1-header-title
{
    font-size: 2.2rem;
    text-align: center;
    font-weight: bold;
}

/* MOBILE */
@media only screen and (max-width: 1000px) {
.atoms_buttons_title_1-header img 
{
    height: 70px;
}
.atoms_buttons_title_1-header-title
{
    height: 2.5rem;
}
}

@media only screen and (max-width: 500px) {
.atoms_buttons_title_1-header img 
{
    height: 50px;
}
.atoms_buttons_title_1-header-title 
{
    font-size: 1.2rem;
    margin: 10px;
}

}