.layers_widget_10-container
{
    padding: 20px;
    background-size: cover;
    min-height: 30vh;
    border-bottom: 3px solid #013401;

}
.layers_widget_10-container-top
{
    display: flex;
    margin-top: 40px;
}

.layers_widget_10-container-top-left
{
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    
}
.layers_widget_10-container-top-right
{
    width: 40%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.layers_widget_10-container-top-right img 
{
    height: 500px;
    object-fit: contain;
}
.layers_widget_10-container-bot
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.layers_widget_10-container-item
{
    margin: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.layers_widget_10-container-item img 
{
    height: 220px;
    object-fit: contain;
}
.layers_widget_10-container-item-name
{
    font-weight: bold;
    padding: 5px;
    font-size: 1.5rem;
    margin-top: 10px;
}   
.layers_widget_10-container-item-headline
{
    font-size: 1.3rem;

}
.layers_widget_10-container-item-modal img
{
    height: 30px;
    object-fit: contain;
    margin-top: 15px;
    animation: layers_widget_10-container-zoom-out 0.3s ease;
    animation-fill-mode: forwards;
}
.layers_widget_10-container-item-modal img:hover
{
    animation: layers_widget_10-container-zoom-in 0.3s ease;
    animation-fill-mode: forwards;
    cursor: pointer;
}
@keyframes layers_widget_10-container-zoom-out {
    from{
        transform: scale(1.3);
    }
    to {
        transform: scale(1);
    }
}
@keyframes layers_widget_10-container-zoom-in {
    from{
        transform: scale(1);
    }
    to {
        transform: scale(1.3);
    }
}

.layers_widget_10-container-item-modal-core
{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0px 20px;
}
.layers_widget_10-container-item-desc
{
    padding: 10px;
    text-align: left;
}
.layers_widget_10-container-item-modal-core-img
{
    height: 130px;
}
/* mobile version */
@media only screen and (max-width: 1100px) {
.layers_widget_10-container-item
{
    margin: 20px;
}
.layers_widget_10-container-top-right img 
{
    height: 400px;
}

}

@media only screen and (max-width: 900px)
{
.layers_widget_10-container
{
    height: auto;

}
.layers_widget_10-container-top-left{
    width: 100%;
}
.layers_widget_10-container-top-right
{
    display: none;
}
.layers_widget_10-container-item-modal-core-img
{
    height: 150px !important;
}
.layers_widget_10-container-item-modal-core
{
    padding: 10px;
}
.layers_widget_10-container-item-name
{
    font-size: 1.1rem;
}
.layers_widget_10-container-item-headline
{
    font-size: 1rem;
}
.layers_widget_10-container-top-left-special
{
    height: 23px !important;
}
}

@media only screen and (max-width: 500px)
{
.layers_widget_10-container-item-name
{
    font-size: 1rem;
}
.layers_widget_10-container-item-headline
{
    font-size: 0.9rem;
}
.layers_widget_10-container-item img 
{
    height: 120px;
}
.layers_widget_10-container-item-modal img 
{
    height: 30px;
}
.layers_widget_10-container-item
{
    margin: 10px;
}
.layers_widget_10-container-item-modal-core
{
    padding: 5px;
}
.layers_widget_10-container-item-modal-core-img
{
    height: 110px !important;
}
.layers_widget_10-container-item-desc
{
    font-size: 0.8rem;
}
}