@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
.morgana_dash-container{
    height: 100vh;
    display: flex;
    background-color: #08032c;
    color: white;
    font-family: 'Poppins', sans-serif;
}
.morgana_dash-container-menu
{
    height: 100%;
    width: 15%;
    border-right: 1px solid white;

}
.morgana_dash-container-menu-logo
{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid white;
}
.morgana_dash-container-menu-logo img 
{
    height: 50px;
    object-fit: contain;
}
.morgana_dash-container-menu-logo span 
{
    margin-top: 5px;
}
.morgana_dash-container-menu-items
{
    margin-top: 20px;
}
.morgana_dash-container-menu-item
{
    padding: 15px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.morgana_dash-container-menu-item:hover 
{
    background-color: #160c61;
    transition: all 0.3s ease;
}
.morgana_dash-container-menu-item img 
{
    height: 40px;
    object-fit: contain;
    margin-right: 20px;
}
.morgana_dash-container-content
{
    height: 100%;
    width: 85%;
    padding: 40px;
}
