.layers_widget_14-container
{
    padding: 20px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    border-bottom: 3px solid #013401;

}
.layers_widget_14-container-items
{
    padding: 30px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

}
.layers_widget_14-container-items-column
{
    width: 50%;
    padding: 5px 10px;
}

.layers_widget_14-container-items-column-item
{
    display: flex;
    align-items: center;
    padding: 5px 10px;
    height: 40px;
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 10px;
}
.layers_widget_14-container-items-column-item img 
{
    height: 25px;
    object-fit: contain;
    margin-right: 20px;
}

@media only screen and (max-width: 1200px) {

.layers_widget_14-container-items-column
{
    width: 50%;
}
.layers_widget_14-container-items-column-item
{
    height: 50px;
}
}
@media only screen and (max-width: 800px) {
.layers_widget_14-container-items
{
    padding: 10px;
    margin-top: 50px;
    flex-direction: column;
}
.layers_widget_14-container-items-column
{
    width: 100%;
}

}
@media only screen and (max-width: 400px) {
.layers_widget_14-container-items-column-item
{
    height: 70px;
    font-size: 1.2rem;
}
.layers_widget_14-container-items
{
    padding: 0px;
}

}