.layers_widget_6-container
{
    height: 100vh;
    border-bottom: 3px solid #013401;
}
.layers_widget_6-container-top
{
    height: 85%;
    display: flex;
}
.layers_widget_6-container-bot
{
    height: 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}
.layers_widget_6-container-bot-item
{
    padding: 10px;
}
.layers_widget_6-container-bot-item img 
{
    height: 30px;
    object-fit: contain;
}
.layers_widget_6-container-left
{
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 40px;
}
.layers_widget_6-container-left-logo
{
    padding: 10px;
}
.layers_widget_6-container-left-logo img 
{
    height: 120px;
    object-fit: contain;
}
.layers_widget_6-container-left-content
{
    padding: 10px;
    text-align: center;
}
.layers_widget_6-container-left-content-title
{
    font-size: 2.3rem;
    padding: 20px;
    font-weight: bold;
}
.layers_widget_6-container-left-content-desc
{
    font-size: 1.2rem;
    padding: 10px;
}
.layers_widget_6-container-left-decor
{
    padding: 20px;
}
.layers_widget_6-container-left-decor img 
{
    height: 90px;
    object-fit: contain;
    transform: rotate(180deg);
}
.layers_widget_6-container-right
{
    height: 100%;
    width: 60%;
}
.layers_widget_6-container-right-myswiper
{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper {
    width: 100%;
    height: 100%;
}
.swiper-slide {

    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100% !important;
    width: 100% !important;
}
/* custom swiper */
.layers_widget_6-container-right-myswiper-item
{
    width: 90%;
    height: 90%;
}
.layers_widget_6-container-right-myswiper-itema-top
{
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.layers_widget_6-container-right-myswiper-itema-top img 
{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.layers_widget_6-container-right-myswiper-itema-bot 
{
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.layers_widget_6-container-right-myswiper-itema-bot  img 
{
    height: 80%;
    object-fit: contain;
}


/* MOBILE  WIDGET VERSION */
@media only screen and (max-width: 1400px) {

.layers_widget_6-container-left
{
    width: 30%;
}
.layers_widget_6-container-right 
{
    width: 70%;
}
}

@media only screen and (max-width: 1400px) {
.layers_widget_6-container-right-myswiper-item
{
    width: 70%;
    height: 70%;
}
}
@media only screen and (max-width: 800px) {
.layers_widget_6-container
{
    /* height: 200vh; */
    height: auto;
    background-size: cover !important;
}
.layers_widget_6-container-top
{
    flex-direction: column;
}
.layers_widget_6-container-left 
{
    width: 100%;
}
.layers_widget_6-container-right 
{
    width: 100%;
}
}