.layers_widget_13-container
{
    padding: 20px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    border-bottom: 3px solid #013401;

}
.layers_widget_13-container-items
{
    padding: 30px;
    display: flex;
}
.layers_widget_13-container-items-left
{
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.layers_widget_13-container-items-left img 
{
    height: 90%;
    object-fit: contain;
    border-radius: 70px;
    height: 360px;
}
.layers_widget_13-container-items-right
{
    width: 65%;
    height: 100%;
    padding: 20px 70px;
}
.layers_widget_13-container-items-right span 
{
    padding: 30px;
    font-size: 1.3rem;
}

/* mobile */
@media only screen and (max-width: 1200px) {
.layers_widget_13-container-items-left img 
{
    height: 250px;
}
.layers_widget_13-container-items-right span 

{
    padding: 20px 30px;
    font-size: 1rem;
}
}
@media only screen and (max-width: 800px) {
.layers_widget_13-container-items
{
    flex-direction: column;
}
.layers_widget_13-container-items-left
{
    width: 100%;
}
.layers_widget_13-container-items-right
{
    width: 100%;
    margin-top: 20px;
}
}
@media only screen and (max-width: 800px) {
.layers_widget_13-container-items
{
    padding: 10px;
}
.layers_widget_13-container-items-right
{
    padding: 10px;
}
.layers_widget_13-container-items-left img 
{
    height: 200px;
    margin-top: 70px;
}

}