.layers_widget_11-container
{
    padding: 20px;
    background-size: cover;
    min-height: 30vh;
    border-bottom: 3px solid #013401;

}
.layers_widget_11-container-left-content
{
    display: flex;
    width: 100%;
    margin-top: 30px;
}
.layers_widget_11-container-content-left
{
    width: 30%;
    
}
.layers_widget_11-container-content-left img 
{
    height: 50%;
    object-fit: contain;
}
.layers_widget_11-container-content-right
{
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.layers_widget_11-container-content-right-item
{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 40px;
}
.layers_widget_11-container-content-right-item img 
{
    height: 230px;
    object-fit: contain;
}
.layers_widget_11-container-content-right-item-name
{
    font-weight: bold;
    padding: 10px;
    font-size: 2rem;
}
.layers_widget_11-container-content-right-item-head
{
    margin-bottom: 10px;
    font-size: 1.5rem;
    font-weight: bold;
}

/* mobile version */
@media only screen and (max-width: 1400px) {
.layers_widget_11-container-content-left img 
{
    width: 90%;
}
}

@media only screen and (max-width: 1000px) {
.layers_widget_11-container-content-left
{
    display: none;
}
.layers_widget_11-container-content-right
{
    width: 100%;
}
}

@media only screen and (max-width: 600px) {
.layers_widget_11-container-content-right-item
{
    margin: 10px;
}
.layers_widget_11-container-content-right-item img
{
    height: 150px;
}
}
@media only screen and (max-width: 400px) {
.layers_widget_11-container-content-right-item
{
    margin: 5px;
}
.layers_widget_11-container-content-right-item-head
{
    font-size: 0.7rem;
}
.layers_widget_11-container-content-right-item img
{
    height: 100px;
}
}