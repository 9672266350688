.layers_widget_7-container
{
    display: flex;
    padding: 20px;
    height: 100vh;
    border-bottom: 4px solid #013401;
}

.layers_widget_7-container-right
{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.layers_widget_7-container-right img 
{
    height: 80%;
    object-fit: contain;
}

.layers_widget_7-container-left
{
    width: 50%;
    display: flex;
}
.layers_widget_7-container-left-dec1
{
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.layers_widget_7-container-left-dec1 img 
{
    transform: rotate(360deg);
}
.layers_widget_7-container-left-content
{
    height: 100%;
    width: 85%;
}
.layers_widget_7-container-left-content-top
{
    height: 80%;
    text-align: center;
}
.layers_widget_7-container-left-content-top-title
{
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    font-weight: bold;
}

.layers_widget_7-container-left-content-top-content 
{
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    padding: 10px;

}
.layers_widget_7-container-left-content-top-btn
{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    /* border: 1px solid blue; */
}
.layers_widget_7-container-left-content-dec2
{
    height: 20%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.layers_widget_7-container-left-content-dec2 img 
{
    transform: rotate(90deg);
    width: auto;
    object-fit: contain;
    margin-left: 170px;
}


/* MOBILE WIDGET VERSION */
@media only screen and (max-width: 1500px)  {
.layers_widget_7-container-right img
{
    height: 60%;
}
}

@media only screen and (max-width: 1000px)  {
.layers_widget_7-container-right img
{
    height: 40%;
}
.layers_widget_7-container-left-dec1 img
{
    height: 30%;
}
.layers_widget_7-container-left-content-dec2 img
{
    width: 20%;
    object-fit: contain;
    margin-left: 50px;
}
.layers_widget_7-container-left-content-top-content p
{
    font-size: 1.1rem;
}
}
@media only screen and (max-width: 800px)  {
.layers_widget_7-container
{
    flex-direction: column;
    height: auto;
    padding-top: 50px;
}
.layers_widget_7-container-left
{
    width: 100%;
}
.layers_widget_7-container-right 
{
    width: 100%;
}
.layers_widget_7-container-left-content-dec2
{
    height: 100px;
}
.layers_widget_7-container-left-content-dec2 img
{
    margin-left: 40px;
}
.layers_widget_7-container-left-content-top-content p
{
    padding: 30px;
}
.layers_widget_7-container-right img
{
    height: 20%;
    width: 70%;
}
}

@media only screen and (max-width: 500px)  {
.layers_widget_7-container-left-dec1
{
    display: flex;
    justify-content: center;
    align-items: center;
}
.layers_widget_7-container-left-dec1 img 
{
    height: 200px;
}
.layers_widget_7-container-left-content-top-title
{
    font-size: 1.5rem;
}
.layers_widget_7-container-left-content-top-content p
{
    font-size: 0.9rem;
    padding: 20px;
}
}