.navbar_model_2-container
{
    height: 10vh;
    min-height: 50px;
    padding: 10px;
    display: -webkit-inline-flex;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navbar_model_2-container-links
{
    height: 100%;
    min-width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar_model_2-container-links-item
{
    padding: 10px;
}
.navbar_model_2-container-links-item a 
{
    text-decoration: none;
    color: white;
    font-size: 0.9rem;
    animation: navbar_model_2_hover_exit 0.3s ease;
    animation-fill-mode: forwards;
    padding: 7px;
}
.navbar_model_2-container-links-item a:hover
{
    animation: navbar_model_2_hover 0.3s ease;
    animation-fill-mode: forwards;
}
@keyframes navbar_model_2_hover {
    from{
        border-bottom: 2px solid transparent;
    }
    to {
        border-bottom: 2px solid var(--navbar_model_2_color_hover);
    }
}
@keyframes navbar_model_2_hover_exit {
    from{
        border-bottom: 2px solid var(--navbar_model_2_color_hover);
    }
    to {
        border-bottom: 2px solid transparent;
    }
}


/* mobile version */
.navbar_model_2-mobile-burger
{
    display: none;
}




/* menu */
.navbar_model_2-mobile-burger-menu
{
    height: 30px;
    width: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navbar_model_2-mobile-burger-menu span 
{
    width: 90%;
    height: 3px;
    background-color: white;
    position: absolute;
    border-radius: 10px;


    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

}

.navbar_model_2-mobile-burger-menu span:nth-child(1) {
    top: 1px;
}
  
.navbar_model_2-mobile-burger-menu span:nth-child(2) {
    top: 10px;
}
  
.navbar_model_2-mobile-burger-menu span:nth-child(3) {
    top: 20px;
}

/* open mobile burger */
.navbar_model_2-mobile-burger-menu-open
{
}


.navbar_model_2-mobile-burger-menu-open span:nth-child(1) {
    top: 11px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}
  
.navbar_model_2-mobile-burger-menu-open span:nth-child(2) {
    top: 10px;
    opacity: 0;
    left: 100px;
}
  
.navbar_model_2-mobile-burger-menu-open span:nth-child(3) {
    top: 12px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.navbar_model_2-mobile-content
{
    height: 100vh;
    margin-top: 10vh;
    display: none;
    width: 0px;
    z-index: 1;
    overflow: hidden;

}

.navbar_model_2-mobile-content-open 
{
    width: 100vw;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    z-index: 2;
}
.navbar_model_2-mobile-content-item
{
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    
}
.navbar_model_2-mobile-content-item a 
{
    border-bottom: 1px solid white;
    padding: 10px;
    text-decoration: none;
    color: white;
    
}

.navbar_model_2-mobile-burger-menu-open.navbar_model_2-mobile-content
{
    /* transform: translateX(0%); */
}

@media only screen and (max-width: 800px)  {

    .navbar_model_2-container-links
    {
        display: none;
    }
    .navbar_model_2-mobile-burger
    {
        display: block;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .navbar_model_2-mobile-content
    {
        display: block;
        position: absolute;
        /* transform: translateX(100%); */
        top: 0px;
        right: 0px;
        transition: all 0.7s ease;
    }
}