.layers_widget_8-container
{
    padding: 20px;
    background-size: cover;
    height: 120vh;
    border-bottom: 3px solid #013401;
}
.layers_widget_8-container-content
{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 70%;
    padding-top: 20px;
}
.layers_widget_8-container-content-item
{
    padding: 10px;
    width: 20%;
    min-width: 200px;
    text-align: center;
}
.layers_widget_8-container-content-item-title
{
    height: 15%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.2rem;
    height: 120px;
    font-weight: bold;

}
.layers_widget_8-container-content-item-decor 
{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 30%;
    width: 100%;
}
.layers_widget_8-container-content-item-img
{
    width: 100%;
    height: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
}
.layers_widget_8-container-content-item-img img 
{
    height: 150px;
    object-fit: contain;
}
.layers_widget_8-container-bot
{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25%;

}
.layers_widget_8-container-bot-wrap
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 20px;
    width: 400px;
    height: 100%;
}

.layers_widget_8-container-bot-wrap span 
{
    transform: translateY(70px);
    font-size: 1.5rem;

}
.layers_widget_8-container-bot-wrap img 
{
    height: 200px;
    width: 200px;
    object-fit: contain;
}

.layers_widget_8-container-bot-center
{
}
.layers_widget_8-container-bot-center img 
{
    height: 140px;
    object-fit: contain;
}


@media only screen and (max-width: 900px) {

.layers_widget_8-container
{
    height: auto;
}
.layers_widget_8-container-content
{
    margin-top: 30px;
}
}

@media only screen and (max-width: 650px) {
.layers_widget_8-container-bot-wrap
{
}
.layers_widget_8-container-bot-wrap span 
{
    transform: translateY(30px);
}
.layers_widget_8-container-bot-wrap img 
{
    width: 150px;
    height: 150px;
}

}
@media only screen and (max-width: 500px) {
.layers_widget_8-container-bot
{
    display: none;
}
.layers_widget_8-container-content-item
{
    min-width: 130px;
}
.layers_widget_8-container-content-item-img img 
{
    height: 100px;
}
.layers_widget_8-container-content-item-title
{
    font-size: 0.9rem;
}
.layers_widget_8-container-content-item-decor img 
{
    height: 70px
}

}