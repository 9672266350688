.layers_widget_3-container
{
    padding: 30px 50px;
    margin-bottom: 30px;
}
.layers_widget_3-container-desktop
{
    border: 1px solid red;
    padding: 10px;
}
.layers_widget_3-container-mobile 
{
    display: none;
    padding: 10px;
}
.layers_widget_3-item
{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 100%;
    background-color: #a68554;
    color: #fff;
    /* margin: 15px; */
    font-size: 4em;
    background-size: cover;
    background-position: center;
}

/* override carouse styles  */
.kXteup:hover:enabled, .kXteup:focus:enabled
{
    /* background-color: #a68554 !important; */
    background-color: var(--widget_3_btn_overwrite);
}
.sc-bcXHqe
{
    background-color: white !important;
}
.sc-bcXHqe:hover
{   
    background-color: var(--widget_3_btn_overwrite) !important;
}
.cVXxbE
{
    /* background-color: #ffe8c6 !important;
    box-shadow: 0 0 1px 3px #a68554 !important; */
    background-color: var(--widget_3_btn_overwrite) !important;
    box-shadow: 0 0 1px 3px var(--widget_3_btn_overwrite) !important;
}

/* Mobile */
@media only screen and (max-width: 500px)  {

.layers_widget_3-container
{
    padding: 0px;
}
}

@media only screen and (max-width: 600px) {
.layers_widget_3-container-desktop  
{
    display: none;
}
.layers_widget_3-container-mobile 
{
    display: block;
}

}