.layers_widget_12-container
{
    padding: 20px;
    background-size: cover;
    min-height: 30vh;
    display: flex;
}
.layers_widget_12-container-left
{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.layers_widget_12-container-left-contacts
{
    width: 100%;
    padding: 10px 20px;

}
.layers_widget_12-container-left-contact
{
    margin-bottom: 10px;
    width:100%;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.2rem;
}
.layers_widget_12-container-left-socials
{
    width: 100%;
    padding: 10px 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.layers_widget_12-container-left-socials img 
{
    margin-left: 20px;
    animation: layers_widget_12-zoom_out 0.3s ease;
    animation-fill-mode: forwards;

}
.layers_widget_12-container-left-socials img:hover 
{
    animation: layers_widget_12-zoom_in 0.3s ease;
}
@keyframes layers_widget_12-zoom_in {
    from{
        transform: scale(1);
    }
    to{
        transform: scale(1.2);
    }
}
@keyframes layers_widget_12-zoom_out {
    from{
        transform: scale(1.2);
    }
    to{
        transform: scale(1);
    }
}
.layers_widget_12-container-left-contact img 
{
    margin-right: 20px;
}
.layers_widget_12-container-right
{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.layers_widget_12-container-right-map
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.layers_widget_12-container-right-decor
{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0px 40px;
}
.layers_widget_12-container-right-decor img 
{
    height: 100px;
    object-fit: contain;
    transform: translateY(-70px);

}

/* MOBILE */
@media only screen and (max-width: 1300px) {
.layers_widget_12-container-right-decor
{
    padding: 0px;
}
.layers_widget_12-container-right-map iframe
{
    width: 400px !important;
    height: 200px !important;
}
.layers_widget_12-container-right-map img 
{
    height: 80px;

}
.layers_widget_12-container-right-decor img
{
    height: 90px;
}
}

@media only screen and (max-width: 900px) {
.layers_widget_12-container
{
    flex-direction: column;
}
.layers_widget_12-container-left
{
    width: 100%;
}
.layers_widget_12-container-right
{
    width: 100%;
}
.layers_widget_12-container-right-decor
{
    transform: translateY(20px);
}
.layers_widget_12-container-right-decor img
{
    height: 70px;
}
}

@media only screen and (max-width: 500px) {
.layers_widget_12-container-right-decor
{
    display: none;
}
.layers_widget_12-container-right-map
{
    padding: 0px;
    width: 100%;
}
.layers_widget_12-container-right-map img 
{
    height: 50px;
    margin-top: 20px;
}
.layers_widget_12-container-right-map iframe
{
    width: 100% !important;
    height: 100% !important;
}
.layers_widget_12-container-left-contact
{
    font-size: 1rem;
}
.layers_widget_12-container-left-socials img 
{
    height: 20px;
}
}

