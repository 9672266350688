*{
  margin:0;
  padding:0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
body{
  min-width: 340px;
}

/* Fonts */  
/* Import custom fonts here for Theme object */

/* First letter: 
font-family: 'Monsieur La Doulaise', cursive;
*/
/* @import url('https://fonts.googleapis.com/css2?family=Monsieur+La+Doulaise&display=swap'); */

/* font-family: 'Faustina', serif; */
@import url('https://fonts.googleapis.com/css2?family=Faustina:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monsieur+La+Doulaise&display=swap');