.layers_footer_1-container
{
    background-size: cover;
    display: flex;
    height: 50vh;
    padding: 50px;
}
.layers_footer_1-container-left
{
    width: 50%;
    height: 100%;
    display: flex;
}
.layers_footer_1-container-left-logo
{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.layers_footer_1-container-left-logo img 
{
    height: 13rem;
    object-fit: contain;
}
.layers_footer_1-container-left-content
{
    width: 50%;
    height: 100%;
    padding: 0px 20px;
}
.layers_footer_1-container-left-content-top
{
    height: 50%;
    font-size: 1.3rem;
}
.layers_footer_1-container-left-content-bot
{
    height: 50%;
}
.layers_footer_1-container-left-content-bot p
{
    font-size: 0.9rem;
    margin-top: 10px;
    border-bottom: 1px solid white;
    padding: 5px 0px;
}
.layers_footer_1-container-right
{
    width: 50%;
    height: 100%;
    display: flex;

}
.layers_footer_1-container-right-column
{
    width: 33.33%;
    height: 100%;
    padding: 0px 20px;
}
.layers_footer_1-container-right-column-ref
{
    margin-bottom: 20px;
    font-weight: 100;
}

.layers_footer_1-container-right-column-ref a {
    font-size: 1rem;
    text-decoration: none;
    color: white;
    border-bottom: 1px solid white;
}

.layers_footer_1-container-right-column-ref a:hover 
{
    color: rgb(187, 187, 187);
    transition: all 0.3s ease;
}
.layers_footer_1-container-right-column-scroll
{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px;
    animation: layers_footer_1-zoom-out 0.3s ease;
    animation-fill-mode: forwards;
}
.layers_footer_1-container-right-column-scroll:hover 
{
    animation: layers_footer_1-zoom-in 0.3s ease;
    animation-fill-mode: forwards;
    cursor: pointer;
}
.layers_footer_1-container-right-column-scroll img 
{
    height: 60%;
}

@keyframes layers_footer_1-zoom-out {
    from{
        transform: scale(1.3);
    }
    to {
        transform: scale(1);
    }
}
@keyframes layers_footer_1-zoom-in {
    from{
        transform: scale(1);
    }
    to {
        transform: scale(1.3);
    }
}

@media only screen and (max-width: 1000px) {
.layers_footer_1-container-left-logo img 
{
    height: 7rem;
}
.layers_footer_1-container
{
    padding: 20px;
}
.layers_footer_1-container-right-column
{
    padding: 0px 10px;
}
}

@media only screen and (max-width: 600px) {
.layers_footer_1-container
{
    flex-direction: column;
    height: auto;
}
.layers_footer_1-container-left-logo
{
    width: 30%;
}
.layers_footer_1-container-left-logo img 
{
    height: 5rem;
    width: 5rem;
}
.layers_footer_1-container-left
{
    width: 100%;
}
.layers_footer_1-container-right
{
    width: 100%;
    margin-top: 20px;
}
.layers_footer_1-container-right-column-scroll
{
    margin: 10px;
}
}