.layers_widget_5-container
{
    padding: 50px;
}
/* .layers_widget_5-container-header
{
    display: flex;
    justify-content: center;
    height: 350px;
    background-size: cover;
    background-position: center;
}
.layers_widget_5-container-header span 
{
    font-size: 3rem;
    color: white;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 170px;
} */
.layers_widget_5-container-header
{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.layers_widget_5-container-header-decor
{
}
.layers_widget_5-container-header-title
{
    font-size: 2.2rem;
    text-align: center;
}