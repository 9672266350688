/* MOBILE */
@media only screen and (max-width: 500px) {
.atoms_buttons_btn_1
{
  padding: 5px 10px !important;
  font-size: 0.9rem !important;
}
}

.atoms_buttons_btn_1
{
  animation: atoms_btn_1_zoom-out 0.3s ease;
  animation-fill-mode: forwards;
}

.atoms_buttons_btn_1:hover
{
  animation: atoms_btn_1_zoom-in 0.3s ease;
  animation-fill-mode: forwards;
}

@keyframes atoms_btn_1_zoom-in {
  from{
    transform: scale(1);
  }
  to{
    transform: scale(1.1);
  }
  
}

@keyframes atoms_btn_1_zoom-out {
  from{
    transform: scale(1.1);
  }
  to{
    transform: scale(1);
  }
}